import type { LinkProps } from 'next/link';
import Link from 'next/link';
import React from 'react';

import type { EncodedRoutePathSpec, Params, RoutePathSpec } from '@/util/route-patterns';
import { routeLink } from '@/util/route-patterns';

type LinkComponentProps<U extends string | null> = U extends string
  ? Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps<U>> &
      LinkProps<U> & {
        children?: React.ReactNode | undefined;
      } & React.RefAttributes<HTMLAnchorElement>
  : never;

export type BoundRouteLinkProps<R extends RoutePathSpec = RoutePathSpec, P extends Params = Params> = {
  route: R;
  params: P;
} & Omit<LinkComponentProps<EncodedRoutePathSpec<R, P>>, 'href' | 'as'>;

const BoundRouteLink = React.forwardRef<HTMLAnchorElement, BoundRouteLinkProps>(
  ({ route, params, ...props }: BoundRouteLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    const linkProps = routeLink(route, params);
    return !linkProps ? null : <Link {...props} {...linkProps} ref={ref} />;
  }
) as (<const R extends RoutePathSpec, const P extends Params>(
  props: BoundRouteLinkProps<R, P>
) => React.JSX.Element | null) & { displayName: string };
BoundRouteLink.displayName = 'RouteLink';

export default BoundRouteLink;
