'use client';

import type { NavListItemProps, NavListSubNavProps, SxProp } from '@primer/react';
import { Box, NavList as PrimerNavList } from '@primer/react';
import cx from 'clsx';
import type React from 'react';

import styles from './NavList.module.scss';

type VisualProps = SxProp & React.HTMLAttributes<HTMLSpanElement>;

/**
 * This is a private `NavBox` (styled `nav`) wrapping an `ActionList`
 */
export const NavList = (props: React.ComponentProps<typeof PrimerNavList>) => (
  <PrimerNavList className={cx(props.className, styles['nav-list'])} {...props} />
);

const NavListItemActiveStyle = {
  fontWeight: 'bold',
  backgroundColor: 'var(--control-transparent-bgColor-selected,var(--color-action-list-item-default-selected-bg))',
};

// LeadingVisual/TrailingVisual container styles
const NavListItemAdjacentVisualStyle = {
  display: 'flex',
  alignItems: 'center',
};

/**
 * An actionable or selectable `Item`.
 *
 * Normally just a `ActionList.LinkItem`, unless it contains a NavListSubNav.
 */
export const NavListItem = <C extends React.ComponentType<React.ComponentPropsWithRef<'a'>>>({
  children,
  leadingVisual,
  trailingVisual,
  ...props
}: NavListItemProps & {
  as?: C;
  leadingVisual?: React.ReactNode;
  trailingVisual?: React.ReactNode;
} & Omit<React.ComponentProps<C>, keyof NavListItemProps>) => {
  // There's a bug in NavItem via ActionList.LinkItem.
  // https://github.com/primer/react/issues/4427
  // https://github.com/primer/react/issues/4367
  // -> https://github.com/primer/react/pull/4392
  const isActive = !!props['aria-current'] && props['aria-current'] !== 'false';
  return (
    <NavListItem_ {...(props as Omit<NavListItemProps, 'children'>)}>
      {!leadingVisual ? null : (
        <NavListLeadingVisual style={NavListItemAdjacentVisualStyle}>{leadingVisual}</NavListLeadingVisual>
      )}
      <Box style={!isActive ? void 0 : NavListItemActiveStyle}>{children}</Box>
      {!trailingVisual ? null : (
        <NavListTrailingVisual style={NavListItemAdjacentVisualStyle}>{trailingVisual}</NavListTrailingVisual>
      )}
    </NavListItem_>
  );
};

const NavListItem_ = PrimerNavList.Item as React.FC<NavListItemProps>;

/**
 * NOTE: SubNav must be a direct child of NavListItem.
 */
export const NavListSubNav = PrimerNavList.SubNav as React.FC<NavListSubNavProps>;

/**
 * Icon (or similar) positioned before `Item` text.
 *
 * NOTE: this is an alias for `ActionList.LeadingVisual`.
 */
export const NavListLeadingVisual = PrimerNavList.LeadingVisual as React.FC<VisualProps>;

/**
 * Icon (or similar) positioned after `Item` text.
 *
 * NOTE: this is an alias for `ActionList.TrailingVisual`.
 */
export const NavListTrailingVisual = PrimerNavList.TrailingVisual as React.FC<VisualProps>;

/**
 * Visually separates `Item`s or `Group`s in an `ActionList`.
 *
 * NOTE: this is an alias for ActionList.Divider.
 */
export const NavListDivider = PrimerNavList.Divider;

/**
 * Collects related `Items` in a `NavList`.
 * NOTE: this is a `ActionList.Divider` followed by an `ActionList.Group`.
 */
export const NavListGroup = PrimerNavList.Group as React.FC<VisualProps>;
