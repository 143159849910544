'use client';

import { useParams } from 'next/navigation';
import React from 'react';

import type { RoutePathSpec } from '@/util/route-patterns';

import type { BoundRouteLinkProps } from './BoundRouteLink';
import BoundRouteLink from './BoundRouteLink';

export type RouteLinkWithContextProps<R extends RoutePathSpec = RoutePathSpec> = Omit<
  BoundRouteLinkProps<R>,
  'params'
> & {
  params?: never;
};

const RouteLinkWithContext = React.forwardRef<HTMLAnchorElement, RouteLinkWithContextProps>(
  ({ route, ...props }: RouteLinkWithContextProps, ref: React.ForwardedRef<HTMLAnchorElement>) => (
    <BoundRouteLink route={route} params={useParams()} {...props} ref={ref} />
  )
) as (<const R extends RoutePathSpec>(props: RouteLinkWithContextProps<R>) => React.JSX.Element | null) & {
  displayName: string;
};
RouteLinkWithContext.displayName = 'RouteLinkWithContext';

export default RouteLinkWithContext;
