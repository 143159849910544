import type React from 'react';
import type { SetOptional } from 'type-fest';

import RouteLink from '@/components/RouteLink';
import { NavList, NavListGroup, NavListItem, NavListSubNav } from '@/lib/primer/NavList';
import type { MatchRoutePath, Params, RoutePathSpec } from '@/util/route-patterns';
import { matchRoutePath } from '@/util/route-patterns';

export interface Group {
  title?: string;
  key: string;
  items: readonly (Item | SubGroup)[];
  match?: MatchRoutePath;
  hidden?: boolean;
  conditions?: readonly GroupCondition[];
}

interface GroupCondition {
  match: MatchRoutePath;

  title?: string;
  hidden?: boolean;
  items?: readonly (Item | SubGroup)[];
}

export interface SubGroup {
  title: string;
  key: string;
  icon: React.ReactNode;
  items: readonly Item[];
}

interface ItemCondition {
  match: MatchRoutePath;

  // Active when matched?
  active?: boolean;
  href?: RoutePathSpec;
  title?: string;
  icon?: React.ReactNode;
  trailingVisual?: React.ReactNode;
  hidden?: boolean;
}

export interface Item {
  title: string;
  key?: string;
  icon?: React.ReactNode;
  // Mark as active when matched?
  active?: boolean;
  hidden?: boolean;
  href: RoutePathSpec;
  match?: MatchRoutePath;
  conditions?: readonly ItemCondition[] | readonly [SetOptional<ItemCondition, 'match'>, ...ItemCondition[]];

  /**
   * If this is a dummy URL, it goes nowhere.
   */
  dummy?: true;
}

export interface SidebarContentProps extends Omit<React.ComponentProps<typeof NavList>, 'children'> {
  currentPage?: string;
  segments: readonly string[];
  params: Params;
  groups: readonly Group[];
}

// Dummy links do not attempt to navigate.
const onClickDoNothing: React.MouseEventHandler<HTMLAnchorElement> = e => void e.preventDefault();

// https://github.com/primer/react/issues/4427
// https://github.com/primer/react/issues/4367
// -> https://github.com/primer/react/pull/4392
// There's a bug in NavItem via ActionList.LinkItem.
// The "sx" props are not propagating correctly, because _PrivateItemWrapper does not accept "sx" props
// from the parent "LiBox" in the ActionList.Item component.
// So although NavItem is passing aria-current="page" correctly, and that does trigger the correct sx style on the
// LiBox, it then gets clobbered by the _PrivateItemWrapper which is not applying the correctly-merged prop.
// Alternatively, maybe NavList.getSubnavStyles could potentially replace "null" with "undefined" to avoid unsetting?
// And/or the contents are overridden by: https://github.com/primer/react/blob/3ec63098e159d5b9fb14e044e5134972ca8dbfb8/packages/react/src/ActionList/Item.tsx#L322
export const SidebarContent = ({ currentPage, params, segments, groups, ...props }: SidebarContentProps) => {
  const item = (item: Item, i: number) => {
    let { key, title, href, active = true, match = href, icon, dummy, hidden = false, conditions = [], ...el } = item;
    let isActive = false;
    let trailingVisual: React.ReactNode = void 0;
    for (const cond of [{ active, match, href, icon, hidden }, ...conditions]) {
      const condActive =
        currentPage != null &&
        (cond.match == null ? isActive : matchRoutePath(cond.match, segments, params, currentPage));
      if (!condActive) {
        continue;
      }
      isActive = true;
      if (cond.active !== undefined) {
        isActive = cond.active;
      }
      if (cond.hidden !== undefined) {
        hidden = cond.hidden;
      }
      if (cond.icon !== undefined) {
        icon = cond.icon;
      }
      if (cond.trailingVisual !== undefined) {
        trailingVisual = cond.trailingVisual;
      }
      if (cond.title !== undefined) {
        title = cond.title;
      }
      if (cond.href !== undefined) {
        href = cond.href;
      }
    }

    if (hidden) {
      return null;
    }

    return (
      <NavListItem
        key={key ?? i}
        route={href}
        params={params}
        as={RouteLink as React.ComponentType<React.ComponentPropsWithRef<'a'>> & typeof RouteLink}
        {...(isActive && { 'aria-current': 'page' })}
        {...(dummy ? { onClick: onClickDoNothing } : {})}
        leadingVisual={icon}
        {...(trailingVisual && { trailingVisual })}
        {...el}
      >
        {title}
      </NavListItem>
    );
  };

  const subGroup = (sg: SubGroup) => {
    const { key, title, items, icon } = sg;
    const els = items.map(item);
    if (!els.length) {
      return null;
    }
    if (true as boolean) {
      return null;
    }
    // NOTE: NavListSubNav seems to be broken?
    return (
      <NavListItem key={key} leadingVisual={icon}>
        {title}
        <NavListSubNav>{els}</NavListSubNav>
      </NavListItem>
    );
  };

  const group = (g: Group) => {
    const { key } = g;
    // If we have a "match" pattern, start as hidden unless that match is active.
    let { title, items, match, hidden = match != null, conditions = [] } = g;
    // But make sure that in that case, if we match, it becomes active.
    for (const cond of g.match ? [{ ...g, hidden: g.hidden ?? false, match: g.match }, ...conditions] : conditions) {
      const condActive = currentPage != null && matchRoutePath(cond.match, segments, params, currentPage);
      if (!condActive) {
        continue;
      }

      if (cond.hidden !== undefined) {
        hidden = cond.hidden;
      }
      if (cond.title !== undefined) {
        title = cond.title;
      }
      if (cond.items !== undefined) {
        items = cond.items;
      }
    }

    if (hidden) {
      return null;
    }

    const els = items.map((el, i) => ('items' in el ? subGroup(el) : item(el, i))).filter(n => n != null);
    if (!els.length) {
      return null;
    }
    return (
      <NavListGroup key={key} {...(title && { title })}>
        {els}
      </NavListGroup>
    );
  };

  return <NavList {...props}>{groups.map(group)}</NavList>;
};

export default SidebarContent;
