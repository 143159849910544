import type { Route } from 'next';

import { encodeSegment } from './PathSegment';

// These types are from `link.d.ts` from nextjs but not exported.
//#region next/link.d.ts

export type SearchOrHash = `?${string}` | `#${string}`;

export type SafeSlug<S extends string = string> = S extends `${string}/${string}`
  ? never
  : S extends `${string}${SearchOrHash}`
    ? never
    : S extends ''
      ? never
      : S;

// type WithProtocol = `${string}:${string}`

// type Suffix = '' | SearchOrHash

// type CatchAllSlug<S extends string> = S extends `${string}${SearchOrHash}`
//   ? never
//   : S extends ''
//   ? never
//   : S

// type OptionalCatchAllSlug<S extends string> =
//   S extends `${string}${SearchOrHash}` ? never : S

//#endregion

export type Params = Record<string, string | readonly string[] | undefined>;

//#region Route Encoding

type RoutePathParam = { param: keyof Params };
type RoutePathSegment = string | RoutePathParam;
type RoutePathSegments = readonly RoutePathSegment[];
type RoutePathSpec_ = string | RoutePathSegments;
export type RoutePathSpec = RoutePathSpec_ | { try: readonly RoutePathSpec_[] };

type ConcatOrNull<A, B> = A extends string ? (B extends string ? `${A}${B}` : null) : null;
type EncodedParamSegment_<A> = A extends string
  ? SafeSlug<A>
  : A extends null
    ? null
    : A extends undefined
      ? null
      : never;
type EncodedParamSegment<S extends RoutePathParam, P extends Params> = S['param'] extends keyof P
  ? EncodedParamSegment_<P[S['param']]>
  : never;
type EncodedRoutePathSegment<S, P extends Params = Params> = S extends string
  ? `/${SafeSlug<S>}`
  : S extends RoutePathParam
    ? ConcatOrNull<'/', EncodedParamSegment<S, P>>
    : never;
type EncodedRoutePathSegments<S, P extends Params = Params> = S extends readonly [infer F, ...infer R]
  ? ConcatOrNull<EncodedRoutePathSegment<F, P>, EncodedRoutePathSegments<R, P>>
  : S extends readonly [infer F]
    ? EncodedRoutePathSegment<F>
    : S extends readonly []
      ? ''
      : never;

type EncodedRoutePathSpecWithoutRewrites_<S extends RoutePathSpec, P extends Params = Params> = [
  RoutePathSpec,
] extends [S]
  ? Route | null
  : S extends { try: ReadonlyArray<RoutePathSpec_> }
    ? EncodedRoutePathSpecWithoutRewrites_<S['try'][number], P>
    : S extends string
      ? EncodedRoutePathSegment<S, P>
      : S extends RoutePathSegments
        ? EncodedRoutePathSegments<S, P>
        : never;
export type EncodedRoutePathSpecWithoutRewrites<S extends RoutePathSpec, P extends Params = Params> = [
  RoutePathSpec,
] extends [S]
  ? Route | null
  : EncodedRoutePathSpecWithoutRewrites_<S, P>;

const encodeRouteSegments = (href: RoutePathSpec, params: Params): string | null => {
  if (typeof href === 'string') {
    return href;
  } else if (Array.isArray(href)) {
    const segments = [];
    for (const p of href) {
      if (typeof p === 'string') {
        segments.push(p);
      } else {
        const v = params[p.param];
        if (!v) {
          // Missing route segment.
          return null;
        }
        segments.push(...(Array.isArray(v) ? v : [v]).map(encodeSegment));
      }
    }
    return '/' + segments.filter(Boolean).join('/');
  } else {
    for (const try_ of href.try) {
      const result = encodeRouteSegments(try_, params);
      if (result != null) {
        return result;
      }
    }
    return null;
  }
};

//#region Rewrites

export type RewrittenRoute<S extends string | null> = S extends null
  ? null
  : S extends `/t${'/' | ''}`
    ? '/orgs'
    : S extends `/t/${SafeSlug<infer S>}${infer Rest}`
      ? `/@${SafeSlug<S>}${Rest}`
      : S;

// Tenant slug route rewrite.
// /t ⇒ /orgs
// /t/ ⇒ /orgs
// /t/foo ⇒ /@/foo
// FIXME: Find a way to get the rewrite rules from next.js!
// Or at least move them out of next.config.mjs and here and centralize the definition.
export const applyRouteRewrites = <S extends string>(pathname: S): RewrittenRoute<S> =>
  pathname
    .replace(/^\/t\/?$/, '/orgs')
    .replace(/^\/t\/([a-z][-a-z0-9]{1,61}[a-z0-9])(\/.*)?$/, '/@$1$2') as RewrittenRoute<S>;

//#endregion

//#region RouteLink

type RouteLinkResult_<H> = H extends string ? { href: H; as: RewrittenRoute<H> } : null;
export type RouteLinkResult<S extends RoutePathSpec, P extends Params> = RouteLinkResult_<
  EncodedRoutePathSpecWithoutRewrites<S, P>
>;

/**
 * Generate a route, returning both the `href` (for pre-rendering, i.e. after rewrites) and `as` (for client-side
 * navigation, before rewrites).
 *
 * These correspond to the `next/link` `href` and `as` properties.
 */
export const routeLink = <const S extends RoutePathSpec, P extends Params>(
  routeSpec: S,
  params: P
): RouteLinkResult<S, P> => {
  const href = encodeRouteSegments(routeSpec, params);
  return (
    !href
      ? null
      : {
          href,
          as: applyRouteRewrites(href),
        }
  ) as RouteLinkResult<S, P>;
};

export type EncodedRoutePathSpec<S extends RoutePathSpec, P extends Params = Params> = RewrittenRoute<
  EncodedRoutePathSpecWithoutRewrites<S, P>
>;

export const encodeRoutePath = <const S extends RoutePathSpec, P extends Params>(
  href: S,
  params: P
): EncodedRoutePathSpec<S, P> => {
  const linkResult = routeLink<S, P>(href, params);
  return (!linkResult ? null : linkResult.as) as EncodedRoutePathSpec<S, P>;
};

//#endregion

//#region Match Route

type MatchRoutePathAtom = { glob: '*' | '**' } | RoutePathSegment;
type MatchRoutePath_ = RegExp | string | readonly MatchRoutePathAtom[];
export type MatchRoutePath = MatchRoutePath_ | { try: readonly MatchRoutePath_[] };

export const matchRoutePath = (
  match: MatchRoutePath,
  segments: readonly string[],
  params: Params,
  currentPage: string
) => {
  // Route groups like "(.)" are not concrete path segments.
  segments = segments.filter(s => !/^\(.+\)$/.test(s));

  if (typeof match === 'string') {
    // Match by prefix
    return !!match && currentPage.startsWith(match);
  } else if (match instanceof RegExp) {
    return match.test(currentPage);
  } else if (Array.isArray(match)) {
    // Match by segment
    let j = 0;
    for (const part of match) {
      if (typeof part === 'string') {
        if (segments[j++] !== part) {
          return false;
        }
      } else if ('glob' in part) {
        if (part.glob === '*') {
          if (!segments[j++]) {
            return false;
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (part.glob === '**') {
          // Should this require a segment to exist?
          return true;
        } else {
          part.glob satisfies never;
          return false;
        }
      } else {
        const v = params[part.param];
        if (v == null) {
          return false;
        }
        for (const vpart of Array.isArray(v) ? v : [v]) {
          if (segments[j++] !== vpart) {
            return false;
          }
        }
      }
    }
    // After visiting all segment matchers, we should have consumed all segments.
    // An optional glob '**' will have returned early.
    return j === segments.length;
  } else {
    // First attempted match.
    for (const m_ of match.try) {
      const match = matchRoutePath(m_, segments, params, currentPage);
      if (match) {
        return true;
      }
    }
    return false;
  }
};
