import React from 'react';

import type { Params, RoutePathSpec } from '@/util/route-patterns';

import type { BoundRouteLinkProps } from './BoundRouteLink';
import BoundRouteLink from './BoundRouteLink';
import type { RouteLinkWithContextProps } from './RouteLinkWithContext';
import RouteLinkWithContext from './RouteLinkWithContext';

export type RouteLinkProps<R extends RoutePathSpec = RoutePathSpec, P extends Params = Params> =
  | BoundRouteLinkProps<R, P>
  | RouteLinkWithContextProps<R>;

const RouteLink = React.forwardRef<HTMLAnchorElement, RouteLinkProps>(
  ({ route, params, ...props }: RouteLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) =>
    params ? (
      <BoundRouteLink route={route} params={params} {...props} ref={ref} />
    ) : (
      <RouteLinkWithContext route={route} {...props} ref={ref} />
    )
) as (<const R extends RoutePathSpec, const P extends Params>(
  props: React.PropsWithChildren<RouteLinkProps<R, P>>
) => React.JSX.Element | null) & { displayName: string };
RouteLink.displayName = 'RouteLink';

export default RouteLink;
