'use client';

import { useParams, usePathname, useSelectedLayoutSegments } from 'next/navigation';

import type { SidebarContentProps } from '@/components/SidebarContent';
import { SidebarContent } from '@/components/SidebarContent';

export interface SidebarCurrentNavProps
  extends Omit<SidebarContentProps, 'currentPage' | 'params' | 'segments' | 'showAll' | 'showDummy'> {}

export const SidebarCurrentNav = (props: SidebarCurrentNavProps) => {
  const pathName = usePathname();
  const params = useParams();
  const segments = useSelectedLayoutSegments();
  return <SidebarContent currentPage={pathName} params={params} segments={segments} {...props} />;
};
