export interface PathSegment {
  segment: string | readonly string[];

  /**
   * Given the following URL structure:
   *  - `/quests`
   *  - `/quests/new`
   *  - `/quest/foo`
   *
   * "/quests/new" is unambiguously a page about quests, and not a quest with an id of "new".
   *
   * However, the parent segment of "/quest/foo" would be "/quest", which is invalid (or at least a redirect).
   * Instead we want the parent segment of "/quest/foo" to be "/quests".
   * In that case, we say that the segment "quest" should become "quests" when it's at leaf position.
   */
  leaf?: string | readonly string[];
}

type PathSegmentsPathAsParent<Head, Tail> = Head extends { segment: infer S extends string | readonly string[] }
  ? PathSegmentsPathsAux<S, Tail>
  : never;

type PathSegmentsPathAsLeaf<Head> = Head extends { leaf: infer S extends string | readonly string[] }
  ? JoinPath<S>
  : Head extends { segment: infer S extends string | readonly string[] }
    ? JoinPath<S>
    : never;

type Join2<A extends string, B extends string> = A extends '' ? B : B extends '' ? A : `${A}/${B}`;
type JoinPath<P extends string | readonly string[]> = P extends string
  ? P
  : P extends readonly []
    ? ''
    : P extends readonly [infer Head extends string, ...infer Tail extends readonly string[]]
      ? Join2<Head, JoinPath<Tail>>
      : P extends readonly [infer Head extends string]
        ? Head
        : never;

type PathSegmentsPathsAux<P, T> = P extends readonly string[]
  ? PathSegmentsPathsAux2<JoinPath<P>, T>
  : P extends string
    ? PathSegmentsPathsAux2<P, T>
    : never;

type PathSegmentsPathsAux2<P extends string, T> = T extends readonly []
  ? never
  : T extends readonly [infer Head, ...infer Tail]
    ? `${P}/${PathSegmentsPathAsLeaf<Head> | PathSegmentsPathAsParent<Head, Tail>}`
    : never;

/**
 * Union of each sequential path segment.
 * `['foo', 'bar']` gives `'/foo' | '/foo/bar'`
 */
export type PathSegmentsPaths<T, P extends string = ''> = PathSegmentsPathsAux<P, T>;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
() => {
  type Test = PathSegmentsPaths<
    [
      { name: 'foo'; segment: 'foo' },
      { name: 'bar'; segment: 'bar'; leaf: 'bars' },
      { name: 'baz'; segment: 'baz' },
      { name: 'q2'; segment: ['qux', '2'] },
      //
    ]
  >;
  type Expected = '/foo' | '/foo/bars' | '/foo/bar/baz' | '/foo/bar/baz/qux/2';
  type Result = [(_: Test) => Test] extends [(_: Expected) => Expected] ? true : false;
  true satisfies Result;
};

const makeURIComponentEncoder = (overrides?: Record<string, string>) => {
  if (!overrides || !Object.keys(overrides).length) {
    return encodeURIComponent;
  }
  const re = new RegExp(`(${Object.keys(overrides).join('|')})`, 'g');
  return (component: string) => {
    re.lastIndex = 0;
    if (!re.test(component)) {
      return encodeURIComponent(component);
    }
    re.lastIndex = 0;
    return component
      .split(re)
      .map((part, i) => (i % 2 === 0 ? encodeURIComponent(part) : (overrides[part] ?? part)))
      .join('');
  };
};

export const encodeSegment = makeURIComponentEncoder({
  // Don't encode "@" as "%40", because we want to support paths like "/@foo".
  '@': '@',
});

export const encodeSegments = (seg: string | readonly string[]) =>
  Array.isArray(seg) ? seg.map(encodeSegment).join('/') : encodeSegment(seg);

export function nextPathSegmentPath<const S extends PathSegment, const P extends string>(
  base: P,
  { segment, leaf }: S
) {
  const pathAsParent = `${base}/${encodeSegments(segment)}` as `${P}/${PathSegmentsPathAsParent<S, P>}`;
  const pathAsLeaf = leaf ? (`${base}/${encodeSegments(leaf)}` as `${P}/${PathSegmentsPathAsLeaf<S>}`) : pathAsParent;
  return { pathAsParent, path: pathAsLeaf };
}

export function* pathSegmentsWithPaths<
  const E extends readonly Seg[],
  const P extends string = '',
  Seg extends PathSegment = E[number],
>(
  segments: Iterable<Seg>,
  base: P = '' as P
): IteratorObject<{ segment: Seg; path: PathSegmentsPaths<E, P> }, BuiltinIteratorReturn> {
  let path;
  for (const segment of segments) {
    ({ pathAsParent: base, path } = nextPathSegmentPath(base, segment));
    yield { segment, path: path as PathSegmentsPaths<E, P> };
  }
}

export const pathSegmentsPaths = <
  const E extends readonly Seg[],
  const P extends string = '',
  Seg extends PathSegment = E[number],
>(
  segments: E,
  base: P = '' as P
): PathSegmentsPaths<E, P>[] =>
  pathSegmentsWithPaths(segments, base)
    .map(({ path }) => path)
    .toArray();
