'use client';

import type { RelativeTimeElement, RelativeTimeUpdatedEvent } from '@github/relative-time-element';
import { RelativeTime as RelativeTime_ } from '@primer/react';
import type React from 'react';
// New version messed up the type of the RelativeTime component's props
// collapsing them down to 'never'

export type {
  Format,
  FormatStyle,
  RelativeTimeUpdatedEvent,
  ResolvedFormat,
  Tense,
} from '@github/relative-time-element';

export type RelativeTimeUpdatedEventHandler = (_: RelativeTimeUpdatedEvent) => void;

type Attrs =
  | 'second'
  | 'minute'
  | 'hour'
  | 'weekday'
  | 'day'
  | 'month'
  | 'year'
  | 'timeZoneName'
  | 'prefix'
  | 'threshold'
  | 'tense'
  | 'onRelativeTimeUpdated'
  | 'precision'
  | 'format'
  | 'formatStyle'
  | 'noTitle'
  | 'datetime'
  | 'date';

type SuperOptional<T> = { [K in keyof T]+?: T[K] | undefined };

interface RelativeTimeHTMLAttributes<T>
  extends React.TimeHTMLAttributes<T>,
    SuperOptional<Pick<RelativeTimeElement, Attrs>> {}

export type RelativeTimeProps = React.DetailedHTMLProps<
  RelativeTimeHTMLAttributes<RelativeTimeElement>,
  RelativeTimeElement
>;

export const RelativeTime = RelativeTime_ as never as React.FC<RelativeTimeProps>;
