'use client';
import { PlusIcon } from '@primer/octicons-react';
import { IconButton, Spinner } from '@primer/react';
import { useParams, useRouter } from 'next/navigation';
import type { MouseEventHandler } from 'react';
import { useCallback, useMemo, useTransition } from 'react';

import type { SidebarContentProps } from '@/components/SidebarContent';
import { encodeRoutePath } from '@/util/route-patterns';

export interface SidebarNavProps extends Omit<SidebarContentProps, 'groups'> {}

const SmallSpinner = () => <Spinner size="small" />;

export const AddQuestSmallSidebarButton = () => {
  // https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
  // This sucks because it means there's no way to detect errors or possible intermediate redirects.

  const params = useParams();
  const destPath = useMemo(
    () =>
      encodeRoutePath(
        {
          try: [
            ['t', { param: 'tenant' }, 'quests', 'new'],
            ['quests', 'new'],
          ],
        },
        params
      ),
    [params]
  );

  const router = useRouter();
  const [isTransitioning, startTransition] = useTransition();

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!isTransitioning && destPath) {
        startTransition(() => void router.push(destPath));
      }
    },
    [router, isTransitioning, destPath]
  );

  return (
    <IconButton
      as="button"
      icon={isTransitioning ? SmallSpinner : PlusIcon}
      size="small"
      aria-label="Create Quest"
      onClick={onClick}
    />
  );
};

export default AddQuestSmallSidebarButton;
