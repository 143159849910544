'use client';

import { isNextRouterError } from 'next/dist/client/components/is-next-router-error';
import React, { Suspense, useMemo } from 'react';
import type { ErrorBoundaryProps, FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

import { hasAuthenticationError } from '@fidant-io/api-client/AuthenticationError';

/** @import { AuthenticationError } from '@fidant-io/api-client/AuthenticationError'; */

type ErrorComponent = React.ComponentType<FallbackProps>;

// This is why this module is marked 'use client':
// > Functions cannot be passed directly to Client Components unless you explicitly expose it by marking it with "use server"

const mkErrorComponent = ({ name, fallback = null }: { name: string; fallback?: React.ReactNode }): ErrorComponent => {
  function ErrorComponent({ error }: { error: Error & { digest?: string }; resetErrorBoundary: () => void }) {
    if (hasAuthenticationError(error)) {
      return fallback;
    }
    if (isNextRouterError(error)) {
      throw error;
    }
    const { name, message, stack, digest, ...errorRest } = error;
    return (
      <pre style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word', wordWrap: 'normal' }}>
        {error.name}: {JSON.stringify({ digest, name, message, ...errorRest, stack }, null, 2)}
      </pre>
    );
  }
  return Object.defineProperty(ErrorComponent, 'displayName', { value: `ErrorComponent(${name})` });
};

export interface AuthErrorBoundaryProps extends Pick<ErrorBoundaryProps, 'onError' | 'onReset'> {
  /**
   * The name of the suspense boundary for displaying in the React DevTools.
   */
  name: string;

  /**
   * The node to render when an {@link AuthenticationError|authentication error} is caught.
   *
   * @default fallback
   */
  fallback?: React.ReactNode;

  /**
   * Values which will trigger a reset of the error boundary when changed.
   */
  resetKeys?: React.DependencyList;
}

export const AuthErrorBoundary = ({
  name,
  fallback,
  resetKeys,
  children,
  ...props
}: React.PropsWithChildren<AuthErrorBoundaryProps>) => (
  <ErrorBoundary
    {...props}
    {...(resetKeys?.length && { resetKeys: resetKeys as [...React.DependencyList] })}
    FallbackComponent={useMemo(() => mkErrorComponent({ name, fallback }), [name, fallback])}
  >
    {children}
  </ErrorBoundary>
);

export interface SuspenseOrAuthBoundaryProps extends Pick<ErrorBoundaryProps, 'onError' | 'onReset'> {
  /**
   * The name of the suspense boundary for displaying in the React DevTools.
   */
  name: string;

  /**
   * The placeholder to render during suspense.
   */
  fallback?: React.ReactNode;

  /**
   * The node to render when an {@link AuthenticationError|authentication error} is caught.
   *
   * @default fallback
   */
  authFallback?: React.ReactNode;

  /**
   * Values which will trigger a reset of the error boundary when changed.
   */
  resetKeys?: React.DependencyList;
}

// https://react.dev/reference/react/Suspense#providing-a-fallback-for-server-errors-and-client-only-content
// "Suspense" acts as special error boundary; errors thrown in server mode are swallowed and retried on client.
// Because of that, I wonder if it makes sense to prefer to catch "real" errors inside the ErrorBoundary, avoiding the
// Suspense retry. But common wisdom (aka brief googling) suggests that ErrorBoundary should be higher in the tree.
export const SuspenseOrAuthBoundary = ({
  name,
  children,
  fallback,
  authFallback = fallback,
  ...props
}: React.PropsWithChildren<SuspenseOrAuthBoundaryProps>) => (
  <AuthErrorBoundary {...props} name={name} fallback={authFallback}>
    <Suspense name={name} fallback={fallback}>
      {children}
    </Suspense>
  </AuthErrorBoundary>
);
