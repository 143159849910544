import(/* webpackMode: "eager", webpackExports: ["Function"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-icons-virtual-06c308df53/0/cache/@blueprintjs-icons-npm-5.19.1-81ffc09e0e-4ff3a64943.zip/node_modules/@blueprintjs/icons/lib/esm/generated/components/function.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/app/(dashboard)/Sidebar/AddQuestSmallSidebarButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarCurrentNav"] */ "/srv/app/packages/web-ui/src/app/(dashboard)/Sidebar/SidebarCurrentNav.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/components/logo/style.module.scss");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/(dashboard)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseOrAuthBoundary"] */ "/srv/app/packages/web-ui/src/components/SuspenseOrAuthBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VerticalDivider"] */ "/srv/app/packages/web-ui/src/components/VerticalDivider.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/lib/primer/index.ts");
