export const isError = (e) => ('isError' in Error ? Error.isError(e) : e instanceof Error);
export const isErrnoException = (e) => isError(e) &&
    (!('errno' in e) || e.errno === undefined || typeof e.errno === 'number') &&
    (!('code' in e) || e.code === undefined || typeof e.code === 'string') &&
    (!('path' in e) || e.path === undefined || typeof e.path === 'string') &&
    (!('syscall' in e) || e.syscall === undefined || typeof e.syscall === 'string') &&
    true;
export const isAbortError = (e) => isError(e) &&
    (e.name === 'AbortError' || ('code' in e && (e.code === 'ABORT_ERR' || e.code === DOMException.ABORT_ERR)));
export const isErrorLike = (e) => 
// "e instanceof Error" checks the prototype, which returns true for things who set __proto__ etc, but does not match
// errors from other realms. "isNativeError" checks the constructor, which matches cross-realm errors and subclasses,
// but not things that set manually set __proto__.
typeof e === 'object' &&
    e !== null &&
    'message' in e &&
    'name' in e &&
    typeof e.message === 'string' &&
    typeof e.name === 'string';
export const toError = (e, message) => {
    let error;
    let needsStack = true;
    if (isError(e)) {
        error = e;
        needsStack = !('stack' in e);
    }
    else if (isErrorLike(e)) {
        error = new Error(e.message || message || String(e) || 'Unknown Error', { cause: e });
        error.name = e.name;
        if ('stack' in e && typeof e.stack == 'string') {
            error.stack = e.stack;
            needsStack = false;
        }
    }
    else {
        error = new Error(message || String(e) || 'Unknown Error', { cause: e });
    }
    if (needsStack) {
        Error.captureStackTrace(error, toError);
    }
    return e;
};
/**
 * Wrap a function `fn` in a try/catch. If an error is thrown and does not already have a cause, it is set to `cause`.
 * Otherwise both the thrown error and `cause` are wrapped in an `AggregateError` and thrown.
 *
 * @param cause
 * @param fn
 * @returns a function accepting the same parameters as `fn` that wraps the call to `fn` in a try/catch.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const assumingCause = (fn, cause) => {
    if (!cause) {
        return fn;
    }
    if (!('stack' in cause)) {
        Error.captureStackTrace(cause, assumingCause);
    }
    return new Proxy(fn, {
        apply: (target, thisArg, args) => {
            try {
                return Reflect.apply(target, thisArg, args);
            }
            catch (e) {
                const err = toError(e);
                if (err.cause == null) {
                    err.cause = cause;
                    throw err;
                }
                else {
                    throw new AggregateError([err, cause]);
                }
            }
        },
    });
};
